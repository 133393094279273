.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*, a, p, h1, h2, li {
  @apply text-black;
}

h1 {
  @apply text-4xl;
  @apply font-bold;
  @apply my-4;
}

p {
  @apply text-lg;
}

h2 {
  @apply text-2xl;
  @apply font-bold;
  @apply my-4;
}

@media only screen and (max-width: 600px) {
  p {
    @apply text-sm;
  }

  h2 {
    @apply text-lg;
    @apply font-bold;
    @apply my-4;
  }
}

